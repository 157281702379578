/** @jsx jsx */
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Flex, Heading, Text, jsx, Button } from 'theme-ui'
import { Link } from 'gatsby'

const ContentCardSection = ({ contentCards }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {contentCards.map(
        ({
          id,
          title,
          description,
          label,
          media,
          slug,
          callToAction,
          ctaLink
        }) => {
          return (
            <Flex
              key={id}
              sx={{
                marginBottom: ['15px', null, '32px'],
                flexDirection: ['column', null, null, 'row'],
                ':nth-of-type(even)': {
                  flexDirection: [null, null, 'row-reverse']
                },
                a: {
                  textDecoration: 'underline',
                  color: 'linkBlue'
                }
              }}
            >
              <Flex
                sx={{ width: ['100%', null, null, '50%'], mr: [0, '15px'] }}
              >
                <GatsbyImage
                  alt={media?.description ?? media?.title}
                  image={media.gatsbyImageData}
                  title={media.title}
                  sx={{ width: '100%' }}
                />
              </Flex>
              <Flex
                sx={{
                  flexDirection: 'column',
                  width: ['100%', null, null, '50%'],
                  padding: ['48px 28px', '48px 36px', '64px 64px 64px 96px'],
                  justifyContent: 'center'
                }}
              >
                <Text
                  as="h3"
                  sx={{
                    marginBottom: ['1.5rem', null, null, null, '2.5rem'],
                    fontSize: ['3rem', null, null, null, '5rem'],
                    textTransform: 'none',
                    fontWeight: 'medium',
                    lineHeight: '1.2'
                  }}
                >
                  {title}
                </Text>
                <Heading
                  as="h4"
                  variant="h4"
                  sx={{
                    fontSize: ['2rem', null, null, null, '2.5rem'],
                    fontStyle: 'italic',
                    fontWeight: 'light',
                    ':after': {
                      content: "''",
                      display: 'block',
                      width: ['7.4rem', '9rem'],
                      height: '1px',
                      backgroundColor: 'black',
                      mt: ['3rem', null, null, null, '4rem'],
                      mb: ['3.8rem', null, null, null, '6rem']
                    }
                  }}
                >
                  {label}
                </Heading>
                <Box
                  sx={{
                    p: { mt: 0, mb: ['2rem', null, null, null, '4rem'] },
                    fontWeight: 'light',
                    fontSize: ['1.3rem', null, null, null, '2rem'],
                    letterSpacing: ['0.13rem', null, null, null, '0.2rem']
                  }}
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html
                  }}
                />
                {callToAction && (
                  <Box
                    sx={{
                      a: {
                        fontSize: ['1.2rem', null, null, null, '1.5rem'],
                        fontWeight: 'medium',
                        fontFamily: 'body'
                      }
                    }}
                  >
                    {ctaLink ? (
                      <Button
                        href={ctaLink}
                        as="a"
                        sx={{
                          textDecoration: 'none !important',
                          color: 'white !important'
                        }}
                        aria-label={`${title} ${callToAction} cta button link`}
                      >
                        {callToAction}
                      </Button>
                    ) : (
                      <Button
                        to={slug}
                        as={Link}
                        sx={{ textDecoration: 'none !important' }}
                        aria-label={`${title} ${callToAction} cta button link`}
                      >
                        {callToAction}
                      </Button>
                    )}
                  </Box>
                )}
              </Flex>
            </Flex>
          )
        }
      )}
    </Flex>
  )
}

ContentCardSection.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ContentCardSection
