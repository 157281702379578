/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Heading } from 'theme-ui'

const CardContent = ({ onScreen, direction, cards }) => {
  return (
    <Box
      sx={{
        position: ['relative', null, 'absolute'],
        top: [
          null,
          null,
          direction === 'vertical' ? 'var(--nav-bar-height)' : 'auto'
        ],
        bottom: [null, null, 0],
        left: [null, null, 0],
        right: [null, null, 0],
        color: ['black', null, direction === 'vertical' ? null : 'white'],
        backgroundColor: [
          'background',
          null,
          direction === 'vertical'
            ? 'transparent'
            : onScreen
            ? 'black70'
            : 'transparent'
        ],
        transition: 'background-color 0.3s'
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: [
            'column',
            null,
            direction === 'vertical' ? 'column' : 'row'
          ],
          justifyContent: [
            null,
            null,
            direction === 'vertical' ? 'center' : 'space-around'
          ],
          alignItems: [
            null,
            null,
            direction === 'vertical' ? 'flex-end' : null
          ],
          width: '100%',
          height: '100%',
          py: ['4.3rem', null, null, null, '6rem']
        }}
      >
        {cards.map(card => (
          <article
            key={card.id}
            sx={{
              '--icon-size': ['3.2rem', null, null, '2.7rem', '4.2rem'],
              position: [
                null,
                null,
                direction === 'vertical' ? 'relative' : null
              ],
              textAlign: ['center', null, null, 'left'],
              width: '100%',
              maxWidth: [
                null,
                null,
                direction === 'vertical' ? '42%' : null,
                '41rem'
              ],
              padding: [
                null,
                null,
                null,
                direction === 'horizontal' ? '4rem 0' : null
              ],
              paddingLeft: [
                null,
                null,
                null,
                direction === 'horizontal'
                  ? 'calc(var(--icon-size) + 1.6rem)'
                  : null
              ],
              'h2::after': {
                content: '""',
                position: 'absolute',
                top: [
                  'calc(100% + 2.4rem)',
                  null,
                  direction === 'vertical' ? '100%' : null,
                  direction === 'horizontal'
                    ? 'calc(100% + 2rem)'
                    : 'calc(100% + 1rem)',
                  direction === 'horizontal'
                    ? 'calc(100% + 4rem)'
                    : 'calc(100% + 2rem)'
                ],
                left: ['50%', null, '0'],
                display: 'block',
                width: '20%',
                height: '1px',
                backgroundColor: [
                  'borderInactive',
                  null,
                  null,
                  direction === 'vertical' ? null : 'white'
                ],
                transform: ['translateX(-50%)', null, 'none'],
                opacity: [
                  null,
                  null,
                  direction === 'vertical' ? 1 : onScreen ? 1 : 0
                ],
                transition: 'opacity 0.3s'
              },
              ':not(:last-of-type)': {
                marginBottom: [
                  '5.6rem',
                  null,
                  direction === 'vertical' ? '2rem' : 0,
                  direction === 'vertical' ? '5rem' : 0
                ],
                marginRight: [
                  null,
                  null,
                  direction === 'horizontal' ? '2.5rem' : null,
                  direction === 'horizontal' ? '3rem' : null
                ]
              },
              ':last-of-type': {
                'h2::after': {
                  display: [
                    null,
                    null,
                    direction === 'vertical' ? 'none' : null
                  ]
                }
              }
            }}
          >
            <Heading
              as="h2"
              mb={4}
              sx={{
                variant: 'text.h2',
                position: [
                  'relative',
                  null,
                  direction === 'vertical' ? 'static' : null
                ],
                display: 'flex',
                flexFlow: ['row nowrap', null, 'column nowrap', 'row nowrap'],
                justifyContent: ['center', null, 'flex-start'],
                alignItems: ['center', null, 'flex-start', 'center'],
                fontSize: [
                  6,
                  null,
                  direction === 'vertical' ? '2.5rem' : 4,
                  direction === 'vertical' ? '2rem' : null,
                  direction === 'vertical' ? '3rem' : 7
                ],
                fontWeight: 'medium',
                whiteSpace: [null, null, 'nowrap'],
                letterSpacing: '2px',
                color: 'inherit',
                margin: 0,
                mb: [
                  '4.7rem',
                  null,
                  direction === 'vertical' ? 0 : onScreen ? null : 0,
                  direction === 'vertical' ? 0 : onScreen ? '4rem' : 0,
                  direction === 'vertical' ? 0 : onScreen ? '8rem' : 0
                ]
              }}
            >
              {card.media ? (
                <GatsbyImage
                  image={card.media.gatsbyImageData}
                  alt={card.media.description ?? card.media.title}
                  objectFit="contain"
                  sx={{
                    alignSelf: [null, null, 'center'],
                    width: 'var(--icon-size) !important',
                    height: 'var(--icon-size) !important',
                    marginRight: '1.6rem',
                    marginLeft: [
                      null,
                      null,
                      null,
                      'calc((var(--icon-size) + 1.6rem) * -1)'
                    ],
                    marginBottom: [null, null, '1.6rem', 0],
                    position: ['absolute', null, 'relative'],
                    left: 0,
                    filter: ['invert()', null, 'none']
                  }}
                />
              ) : null}

              <div sx={{ maxWidth: ['200px', 'none'] }}>{card.label}</div>
            </Heading>

            <div
              dangerouslySetInnerHTML={{
                __html: card.description.childMarkdownRemark.html
              }}
              sx={{
                fontSize: [2, null, null, null, '2rem'],
                textAlign: ['center', null, 'left'],
                maxHeight: [null, null, !onScreen ? 0 : '30rem'],
                overflow: [null, null, 'hidden'],
                ul: { variant: 'list.bare' },
                li: {
                  marginBottom: [
                    '2.2rem',
                    null,
                    direction === 'vertical' ? '0.8rem' : null,
                    direction === 'vertical' ? '0.8rem' : '2.6rem'
                  ]
                },
                transition: 'max-height 0.3s ease-in'
              }}
            />
          </article>
        ))}
      </Container>
    </Box>
  )
}

export default CardContent
