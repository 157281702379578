/** @jsx jsx */
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'

const ArticleCard = ({ article }) => {
  const { title, slug, description, image } = article
  return (
    <Link
      to={`/blog/${slug}`}
      sx={{
        width: '100%',
        ':hover .description:before': {
          width: [null, null, '100%']
        }
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column'],
          mb: '50px'
        }}
      >
        {image && (
          <Box sx={{ width: '100%' }}>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description ?? image.title}
              sx={{
                height: '100%',
                py: ['2px']
              }}
            />
          </Box>
        )}
        <Heading
          as="h2"
          variant="h2"
          sx={{
            textAlign: 'left',
            color: 'primary',
            fontSize: ['1.5rem', '2rem'],
            marginTop: ['20px', null, '27px'],
            textTransform: 'uppercase',
            fontWeight: 'medium'
          }}
        >
          {title}
        </Heading>
        {description && (
          <Text
            sx={{
              fontSize: ['1.3rem', '1.7rem'],
              color: 'primary',
              textAlign: 'left',
              p: {
                mt: 0,
                mb: '23px'
              }
            }}
          >
            <div
              className="description"
              sx={{
                position: 'relative',
                ':after': {
                  content: '""',
                  display: 'block',
                  width: ['82px', '100px', '151px'],
                  height: '4px',
                  backgroundColor: 'black'
                },
                ':before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: '2px',
                  width: ['82px', '100px', '151px'],
                  height: '2px',
                  backgroundColor: 'black',
                  transition: 'width 0.7s cubic-bezier(.09,.24,.28,1.01)'
                }
              }}
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          </Text>
        )}
      </Flex>
    </Link>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default ArticleCard
