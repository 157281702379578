/** @jsx jsx */
import { useState } from 'react'
import { Flex, jsx } from 'theme-ui'
import { Carousel } from 'react-responsive-carousel'

import ArticleCard from '~/components/Article/Card'
import { useWindowSize } from '~/hooks/utils/use-window-size'
import DetailArticleCard from '../Card/Detail'
import LocalInfiniteScroll from '~/components/Generic/LocalInfiniteScroll'

const ArticleList = ({ articles, blog }) => {
  const [selected, setSelected] = useState(0)
  const { width } = useWindowSize()
  return width < 768 && !blog ? (
    <Carousel
      onChange={e => {
        setSelected(e)
      }}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      autoPlay={false}
      showArrows={false}
      swipeable={true}
      emulateTouch={true}
      centerSlidePercentage={70}
      centerMode={true}
      sx={{
        width: '100%',
        '.slide': {
          backgroundColor: 'transparent'
        },
        ul: {
          ml: selected === 0 ? '15% !important' : 0
        }
      }}
    >
      {articles.map((article, index) => (
        <Flex sx={{ width: '80%', margin: '0 auto' }} key={article.id}>
          <ArticleCard key={index} article={article} />
        </Flex>
      ))}
    </Carousel>
  ) : blog ? (
    <LocalInfiniteScroll
      items={articles}
      renderItems={items => (
        <Flex sx={{ margin: '0 auto', flexDirection: 'column' }}>
          {items.map((article, index) => (
            <Flex key={article.id}>
              <DetailArticleCard key={article.id} article={article} />
            </Flex>
          ))}
        </Flex>
      )}
    />
  ) : (
    <Flex sx={{ margin: '0 auto', flexDirection: 'row', width: '100%' }}>
      {articles.map((article, index) => (
        <Flex
          sx={{
            minWidth: '30%',
            maxWidth: '30%',
            margin: '0 auto'
          }}
          key={article.id}
        >
          <ArticleCard key={index} article={article} />
        </Flex>
      ))}
    </Flex>
  )
}

export default ArticleList
