/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Button, Card, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import CollectionLink from '~/components/Collection/Link'
import { useRef } from 'react'
import { useOnScreen } from '~/hooks/utils/use-on-screen'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

const CollectionCard = ({ collection, hideSecondBtn, exclusive }) => {
  const {
    title,
    mainImage,
    mobileImage,
    description,
    tagLine,
    textColor,
    textPosition,
    overlay,
    imagePosition
  } = collection
  const translate = useTranslate()

  const textPos = textPosition || 'Left'

  const position = {
    Left: 'flex-start',
    Right: 'flex-end',
    Center: 'center'
  }

  const imagePositions = {
    Top: 'center 15%',
    Center: 'center',
    Bottom: 'center 85%'
  }

  const cardRef = useRef()
  const onScreen = useOnScreen(cardRef, { threshold: 0.5 })

  const images =
    mainImage?.gatsbyImageData && mobileImage?.gatsbyImageData
      ? withArtDirection(getImage(mainImage.gatsbyImageData), [
          {
            media: '(min-width: 768px)',
            image: getImage(mobileImage.gatsbyImageData)
          }
        ])
      : mainImage?.gatsbyImageData

  return (
    <Card
      ref={cardRef}
      sx={{
        padding: ['0', '0', '0'],
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: position[textPos],
        position: 'relative',
        scrollSnapAlign: 'center',

        height: ['375px', null, '425px', '723px'],
        ':first-of-type': {
          mt: 'var(--nav-bar-height)'
        }
      }}
    >
      <GatsbyImage
        image={images}
        alt={images?.description ?? images?.title}
        sx={{ position: 'absolute !important', width: '100%', height: '100%' }}
        imgStyle={{ objectPosition: imagePositions[imagePosition] }}
      />
      <Flex
        className="cardContent"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          flexGrow: 1,
          py: ['16px', '24px', '32px'],
          pl: textPos === 'Left' ? ['20px', '52px', '92px', '212px'] : 0,
          pr: textPos === 'Right' ? ['20px', '52px', '92px', '212px'] : 0,
          color: textColor || 'black',
          zIndex: 1,
          transition: ['opacity 0.1s ease-in-out 0.1s'],
          opacity: [onScreen ? 1 : 0],
          pointerEvents: [onScreen ? 'auto' : 'none']
        }}
      >
        <CollectionLink
          collection={collection}
          sx={{
            textTransform: 'none',
            textAlign: [textPos.toLowerCase(), null, 'left', null],
            fontWeight: 'medium'
          }}
        >
          <Text
            variant="textLink"
            sx={{
              paddingBottom: ['8px', null, '12px'],
              paddingTop: ['8px', null, null],
              fontSize: ['11px', '10px', null, '20px'],
              fontWeight: 'medium'
            }}
          >
            <span
              sx={{
                maxWidth: ['13.4rem', 'none', null],
                display: 'inline-block'
              }}
            >
              {tagLine || translate('collections.title')}
            </span>
          </Text>

          <Heading
            as="h2"
            variant="h2"
            sx={{
              paddingBottom: ['8px', null, '12px'],
              color: 'inherit',
              fontSize: ['2.8rem', '2.5rem', null, '3rem', '4rem'],
              textTransform: 'uppercase',
              fontWeight: 'medium'
            }}
          >
            {title}
          </Heading>
        </CollectionLink>
        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'space-between'
          }}
        >
          {description && (
            <Box
              className="description"
              sx={{
                variant: 'text.h3',
                display: ['none', null, 'block'],
                color: 'inherit',
                maxWidth: ['270px', null, null, '394px', null],
                textAlign: textPos === 'Center' ? 'center' : 'left',
                textShadow: '0 0 10px rgba(255, 255, 255, 0.639)',
                lineHeight: '25px',
                fontWeight: 'light',
                strong: { fontWeight: 'medium' },
                p: {
                  margin: 0,
                  ':first-of-type': {
                    margin: ['2.22rem 0 0', null, null, '4.43rem 0 0']
                  }
                }
              }}
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          )}
          <Grid
            className="ctaButtons"
            gap={['10px 0', '0 11px', null, '0 17px', null, null]}
            sx={{
              gridTemplateColumns: [
                '140px',
                hideSecondBtn ? '200px' : '200px 137px',
                null,
                hideSecondBtn ? '245px' : '245px 156px',
                hideSecondBtn ? '275px' : '275px 186px'
              ],
              justifyContent: [
                position[textPos],
                textPos === 'Center' ? 'center' : 'flex-start',
                null
              ],
              button: {
                fontSize: ['9px', '12px', '13px', '15px'],
                px: 0,
                fontWeight: 'medium',
                fontFamily: 'primary',
                height: ['3.2rem', null, '2.9rem', '3.5rem', '4.4rem'],
                lineHeight: ['3.2rem', null, '2.9rem', '3.5rem', '4.4rem']
              },
              mt: ['35px', '2.3rem', null, '3.1rem', '50px']
            }}
          >
            <CollectionLink collection={collection}>
              <Button
                sx={{
                  width: ['100%'],
                  borderColor:
                    textColor === 'White' ? 'borderInverted' : 'border',
                  color:
                    textColor === 'White'
                      ? 'white !important'
                      : 'black !important',
                  ':hover': {
                    backgroundColor: textColor?.toLowerCase(),
                    color:
                      textColor === 'White'
                        ? 'black !important'
                        : 'white !important'
                  }
                }}
              >
                {exclusive ? 'SHOP EXCLUSIVE' : 'BROWSE COLLECTION'}
              </Button>
            </CollectionLink>
            {!hideSecondBtn && (
              <CollectionLink collection={collection}>
                <Button
                  color="inherit"
                  sx={{
                    width: ['100%']
                  }}
                >
                  SHOP ALL
                </Button>
              </CollectionLink>
            )}
          </Grid>
        </Flex>
      </Flex>
      {overlay && (
        <Box
          sx={{
            background:
              textColor === 'Black'
                ? 'linear-gradient(to right, rgba(256,256,256,0.6), transparent)'
                : 'linear-gradient(to right, rgba(0,0,0,0.6), transparent)',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        />
      )}
    </Card>
  )
}

CollectionCard.propTypes = {
  collection: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({}),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default CollectionCard
