/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Heading, Container, jsx, Text } from 'theme-ui'
import ArticleList from '~/components/Article/List'
import Lightning from '~/assets/images/icons/lightning.svg'
import RichTextRenderer from '../../RichText'

const ArticleSection = ({ title, heading, description, articles, blog }) => {
  return (
    <Container
      variant="fullWidth"
      sx={{
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        minHeight: [null, null, null, blog ? null : '720px'],
        height: [null, null, null, blog ? null : '90vh'],

        padding: '0',
        pt: ['8rem', null, null, 0],
        pb: ['12rem', null, null, 0],
        px: [0, null, null, blog ? '4rem' : '10rem', blog ? '7rem' : '20rem'],
        mt: [blog ? 3 : 0, null, null, blog ? 5 : 0]
      }}
    >
      <Box
        sx={{
          marginBottom: ['0', null, null, null, '24px'],
          padding: ['0 20px 16px', null, null, '0 0 32px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            variant: 'text.h1',
            display: 'block',
            textAlign: 'center',
            color: 'accent',
            width: ['100%', '65%'],
            margin: 'auto',
            textTransform: 'uppercase',
            letterSpacing: '0.2rem',
            fontSize: ['2.6rem', null, null, null, '4.8rem'],
            fontWeight: 'medium',
            mb: ['2.8rem', null, '2rem', null, '3rem', '5.8rem']
          }}
        >
          {title}
        </Heading>
        {heading && (
          <Heading
            as="h2"
            sx={{
              variant: 'text.h2',
              display: 'block',
              textAlign: 'center',
              color: 'accent',
              width: ['100%', '65%'],
              margin: 'auto',
              textTransform: 'uppercase',
              letterSpacing: '0.2rem',
              fontSize: ['2rem', null, null, null, '3rem'],
              fontWeight: 'medium',
              mb: ['4.8rem', null, '2rem', null, '3rem', '5.8rem']
            }}
          >
            <RichTextRenderer richText={heading} />
          </Heading>
        )}
        {description && (
          <Text
            sx={{
              variant: 'text.body',
              color: 'primary',
              textAlign: 'center'
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          </Text>
        )}
      </Box>
      <ArticleList articles={articles} blog={blog} />

      {!blog && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '2.4rem',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <Lightning />
        </Box>
      )}
    </Container>
  )
}

ArticleSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  articles: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ArticleSection
