/** @jsx jsx */
/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, Box, Container, Heading } from 'theme-ui'
import PropTypes from 'prop-types'
import { useRef, useEffect } from 'react'
import { useOnScreen } from '~/hooks/utils/use-on-screen'
import { GatsbyImage } from 'gatsby-plugin-image'
import CollectionLink from '~/components/Collection/Link'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

const BikeCollectionCard = ({ collection, onVisibilityChange }) => {
  const containerRef = useRef()
  const isVisible = useOnScreen(containerRef, { threshold: 0.4 })
  const isMobileTabletViewport = useMatchMedia('(max-width: 1024px)')

  useEffect(() => {
    onVisibilityChange(isVisible)
  }, [isVisible])

  return (
    <Box
      as="section"
      sx={{
        '--transition-duration': '0.3s',
        '--transition-delay': '0.3s',
        position: 'relative',
        height: '100vh'
      }}
      ref={containerRef}
      id={collection.title}
    >
      {isMobileTabletViewport ? (
        <GatsbyImage
          image={
            collection.mobileImage
              ? collection.mobileImage.gatsbyImageData
              : collection.mainImage?.gatsbyImageData
          }
          title={
            collection.mobileImage?.title || collection.mainImage?.title || ''
          }
          alt={
            collection.mobileImage?.description ?? collection.mobileImage?.title
          }
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          imgStyle={{ height: '100%' }}
        />
      ) : (
        <GatsbyImage
          image={collection?.mainImage?.gatsbyImageData}
          title={collection.mainImage?.title || ''}
          alt={collection.mainImage?.description ?? collection.mainImage?.title}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          imgStyle={{ height: '100%' }}
        />
      )}

      <Container
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: [null, null, 'flex-end'],
          alignItems: ['flex-end', null, 'center'],
          padding: [
            '2.4rem var(--horizontal-inset) calc(env(safe-area-inset-bottom) + 10rem)',
            '2.4rem var(--horizontal-inset) calc(env(safe-area-inset-bottom) + 12rem)',
            '2.4rem var(--horizontal-inset) 0'
          ],
          opacity: isVisible ? 1 : 0,
          zIndex: isVisible ? 1 : 0,
          transition:
            'opacity var(--transition-duration) ease-in-out, z-index var(--transition-duration) ease-in'
        }}
      >
        <Box
          sx={{
            maxWidth: [null, null, '32rem', '36rem', '53.4rem'],
            width: '100%',
            height: 'min-content',
            color: 'white',
            textShadow: '0 0 25px black',
            margin: ['0 auto', null, 0],
            marginBottom: [
              isVisible ? '5rem' : '-8rem',
              null,
              isVisible ? '0' : '-8rem'
            ],
            transition:
              'opacity var(--transition-duration) 0.2s, margin var(--transition-duration) 0.2s',
            opacity: isVisible ? 1 : 0
          }}
        >
          <Heading
            as="h2"
            variant="h2"
            sx={{
              color: 'inherit',
              borderColor: 'white',
              fontWeight: 'medium',
              letterSpacing: '4px',
              fontSize: [8, null, null, null, 11],
              mb: ['4rem', null, '4rem', null, '9rem'],
              textTransform: 'uppercase'
            }}
          >
            {collection.title}
          </Heading>

          <div
            dangerouslySetInnerHTML={{
              __html: collection.description?.childMarkdownRemark.html
            }}
            sx={{
              fontSize: [6, null, null, null, 8],
              marginBottom: ['4rem', null, '5rem', null, '10rem'],
              ul: { variant: 'list.bare' },
              li: { marginBottom: 2 },
              p: {
                margin: 0,
                marginBottom: ['2rem', null, '2.5rem', null, '4rem'],
                letterSpacing: '2px',
                ':first-of-type': {
                  fontWeight: 'bold'
                },
                ':last-of-type': {
                  mb: 0
                }
              }
            }}
          />

          <CollectionLink
            collection={collection}
            sx={{
              variant: 'buttons.primary',
              '--color': 'white',
              '--border-color': 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: ['16rem', null, null, null, '18rem'],
              height: ['3.3rem', null, '3rem', null, '5.3rem'],
              ':hover': {
                '--color': 'black',
                '--background-color': 'white',
                '--border-color': 'white'
              }
            }}
          >
            Explore {collection.title}
          </CollectionLink>
        </Box>
      </Container>
    </Box>
  )
}

BikeCollectionCard.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      fluid: PropTypes.object.isRequired
    }).isRequired,
    lineup: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        lineup: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
          })
        )
      })
    ).isRequired
  }).isRequired,
  onVisibilityChange: PropTypes.func
}

BikeCollectionCard.defaultProps = {
  onVisibilityChange: () => {}
}

export default BikeCollectionCard
