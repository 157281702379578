/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'

const CollectionLink = ({ children, sx, collection, ...props }) => {
  const { trackCollectionClicked } = useAnalytics()

  const [parentLineup] = collection.lineup ?? []
  const [rootLineup] = parentLineup?.lineup ?? []

  const url = `/${
    rootLineup
      ? rootLineup.breadcrumbSlug || rootLineup.slug
      : parentLineup.breadcrumbSlug || parentLineup.slug
  }/${collection.slug}`

  return (
    <Link
      to={url}
      onClick={() =>
        trackCollectionClicked({
          id: collection.id,
          title: collection.title,
          slug: collection.slug
        })
      }
      sx={{
        ...sx
      }}
      aria-label={collection.title || collection.name}
      {...props}
    >
      {children}
    </Link>
  )
}

CollectionLink.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    lineup: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        lineup: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
          })
        )
      }).isRequired
    )
  }),
  children: PropTypes.node.isRequired
}

export default CollectionLink
