/** @jsx jsx */
import { jsx, Box, Button, Flex } from 'theme-ui'
import ProductLink from '~/components/Product/Link'
import { useTranslate, utils } from '@chordcommerce/gatsby-theme-performance'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductChooseVariantButton from '~/components/Product/ChooseVariantButton'
import ArrowBack from '~/assets/images/icons/s73-arrow-left.svg'
import ArrowNext from '~/assets/images/icons/s73-arrow-right.svg'
import { useWindowSize } from '~/hooks/utils/use-window-size'
import { Fragment, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ImgLoader from '~/components/Generic/ImgLoader'
import { useIntl } from 'gatsby-plugin-intl'
import formatMoney from '~/utils/formatMoney'
import Zoomable from 'react-instagram-zoom'

const SliderButton = ({ children, onClick, ariaLabel, forwardSx }) => (
  <Button
    variant="iconOnly"
    aria-label={ariaLabel}
    onClick={onClick}
    sx={{
      position: 'absolute',
      bottom: ['-40px', null, '-60px'],
      zIndex: 1,
      ...forwardSx
    }}
  >
    {children}
  </Button>
)

const BikeCarousel = ({ bikes }) => {
  const { locale } = useIntl()

  const { toUsdCurrency } = utils
  const translate = useTranslate()

  const [curProd, setCurProd] = useState(bikes[0])
  const { name, shortDescription, variants, cardSpecs } = curProd

  const { price, regularPrice, sku, shopifyId } = variants[0]

  const { width } = useWindowSize()

  return (
    <Flex
      sx={{
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        pt: ['8rem', null, null, 0],
        pb: ['8rem', null, null, 0],
        minHeight: [null, null, null, '100vh'],
        '.carousel.carousel-slider': { overflow: 'visible' },
        '.slick-slide': { position: 'relative' },
        '.slick-list': { overflow: 'visible !important' }
      }}
    >
      <Slider
        dots={false}
        arrows
        infinite
        speed={500}
        lazyLoad={true}
        slidesToShow={width < 768 ? 1 : 2.3}
        centerMode={width >= 768}
        slidesToScroll={1}
        afterChange={e => {
          setCurProd(bikes[e])
        }}
        prevArrow={
          <SliderButton
            ariaLabel="Previous"
            forwardSx={{ left: ['4px', null, '70px'] }}
          >
            <ArrowBack
              sx={{ width: ['20px', '25px'], height: ['12px', '25px'] }}
            />
          </SliderButton>
        }
        nextArrow={
          <SliderButton
            ariaLabel="Next"
            forwardSx={{ right: ['4px', null, '70px'] }}
          >
            <ArrowNext
              sx={{
                width: ['20px', '25px'],
                height: ['12px', '25px']
              }}
            />
          </SliderButton>
        }
      >
        {bikes.map(product => (
          <Zoomable key={product.mainImage.title}>
            <ProductLink product={curProd}>
              <ImgLoader
                image={product.mainImage}
                alt={product.mainImage.description ?? product.mainImage.title}
                forwardSx={{
                  maxHeight: '785px',
                  img: { objectFit: 'contain !important' },
                  cursor: 'grab'
                }}
              />
            </ProductLink>
          </Zoomable>
        ))}
      </Slider>

      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <ProductLink
          className="productName"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            textTransform: 'uppercase',
            fontSize: ['25px', '35px', null, '40px'],
            mb: ['-6px', null, null, '-9px'],
            fontWeight: 'medium',
            textAlign: 'center',
            letterSpacing: '0.2rem'
            // marginBottom: ['4px', null, '6px']
          }}
          product={curProd}
        >
          {name}
        </ProductLink>
        <ProductLink
          className="description"
          sx={{
            display: 'block',
            textTransform: 'none',
            variant: 'text.small',
            textAlign: 'center',
            fontSize: ['13px', '15px']
          }}
          product={curProd}
        >
          {shortDescription}
        </ProductLink>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: ['column', null, 'row'],
            mt: '28px',
            '.specs > div, .cartBtn': {
              px: ['18px', null, null, '45px'],
              height: '55px',
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          <Flex
            className="specs"
            sx={{
              mb: ['32px', null, '0px'],
              '& > div': { borderRight: '1px solid black' },
              '& > div:last-child': {
                borderRight: ['none', null, '1px solid black']
              }
            }}
          >
            {cardSpecs?.map(spec => {
              return (
                <Box key={spec.id}>
                  <ProductLink
                    sx={{
                      variant: 'text.small',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      lineHeight: ['140%', '170%'],
                      textTransform: 'none'
                    }}
                    product={curProd}
                  >
                    <div
                      sx={{
                        fontSize: ['20px', '30px'],
                        mb: '5px',
                        fontWeight: 'medium'
                      }}
                    >
                      {spec.value}
                    </div>
                    <div
                      sx={{
                        fontSize: ['12px', '15px'],
                        textAlign: 'center',
                        whiteSpace: [null, null, 'nowrap']
                      }}
                    >
                      {spec.name}
                    </div>
                  </ProductLink>
                </Box>
              )
            })}
            <Box>
              <ProductLink
                sx={{
                  variant: 'text.small',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  lineHeight: ['140%', '170%']
                }}
                product={curProd}
              >
                <div
                  sx={{
                    fontSize: ['20px', '30px'],
                    mb: '5px',
                    fontWeight: 'medium'
                  }}
                >
                  {regularPrice !== price ? (
                    <Fragment>
                      <div
                        sx={{
                          textDecoration: 'line-through',
                          margin: ['6px 0px', '10px 0px', null, '0 10px 0 0'],
                          display: ['block', null, null, 'inline']
                        }}
                      >
                        {formatMoney(regularPrice, { locale })}
                      </div>
                      <div
                        sx={{
                          color: 'red',
                          display: ['block', null, null, 'inline']
                        }}
                      >
                        {formatMoney(price, { locale })}
                      </div>
                    </Fragment>
                  ) : (
                    formatMoney(price, { locale })
                  )}
                </div>
                <div sx={{ fontSize: ['13px', '15px'] }}>USD</div>
              </ProductLink>
            </Box>
          </Flex>
          <Box className="cartBtn">
            <ProductChooseVariantButton
              product={curProd}
              aria-labelledby={curProd.name}
              ctaText={translate('product.learn_more')}
              sx={{
                width: ['122px', '178px'],
                height: ['36px', '44px'],
                fontSize: ['11px', '15px'],
                margin: '0 auto',
                fontWeight: 'bold',
                color: 'white !important',
                background: 'black !important'
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BikeCarousel
