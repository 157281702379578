/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, jsx } from 'theme-ui'
import useBreakPointValues from '~/hooks/utils/use-breakpoint-values'
import Marquee from 'react-marquee-slider'
import { useEffect, useRef, useState } from 'react'
import { useOnScreen } from '~/hooks/utils/use-on-screen'

const ImageMarquee = ({ heading, images }) => {
  const velocity = useBreakPointValues([5, 10, 15, 20])
  const ref = useRef()
  const onScreen = useOnScreen(ref)
  const [onScreenOnce, setOnScreenOnce] = useState(false)
  useEffect(() => {
    if (onScreen && !onScreenOnce) {
      setOnScreenOnce(true)
    }
  }, [onScreen])
  return (
    <Box
      ref={ref}
      sx={{
        mb: ['77px', '111px'],
        height: onScreenOnce ? null : ['50vw', '33vw', '20vw', '15vw'],
        '& > div:last-child': {
          animation: 'showResults 0.5s ease'
        }
      }}
    >
      {heading && (
        <div
          sx={{
            fontSize: ['13px', '20px'],
            fontWeight: 'light',
            color: 'textMuted',
            p: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '5px',
              img: {
                mr: '10px',
                width: '16px',
                height: '16px'
              },
              a: {
                ml: '6px'
              }
            }
          }}
          dangerouslySetInnerHTML={{
            __html: heading.childMarkdownRemark.html
          }}
        />
      )}
      {onScreenOnce && (
        <Marquee velocity={velocity}>
          {images.map(img => {
            return (
              <Box
                key={img.title}
                sx={{
                  width: ['50vw', '33vw', '20vw', '15vw'],
                  height: ['50vw', '33vw', '20vw', '15vw'],
                  mr: '9px',
                  '& > div': { width: '100%', height: '100%' }
                }}
              >
                <GatsbyImage
                  alt={img?.description ?? img?.title}
                  image={img.gatsbyImageData}
                />
              </Box>
            )
          })}
        </Marquee>
      )}
    </Box>
  )
}

export default ImageMarquee
