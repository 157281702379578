/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Card, Flex, jsx } from 'theme-ui'
import ImgLoader from '~/components/Generic/ImgLoader'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductChooseVariantButton from '~/components/Product/ChooseVariantButton'
import ProductLink from '~/components/Product/Link'

const ProductSlide = ({ product, productPath, ...props }) => {
  const { mainImage, name, variants } = product

  const {
    price,
    regularPrice,
    sku,
    shopifyId,
    limitOnePerCustomer,
    comingSoon
  } = variants[0]
  return (
    <Card
      {...props}
      sx={{
        display: 'flex !important',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        px: '0',
        height: '100%'
      }}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <ImgLoader
          image={mainImage}
          alt={mainImage.description ?? mainImage.title}
          key={mainImage.title}
          forwardSx={{
            width: ['20rem', null, '22rem', '26rem', '42rem'],
            height: ['20rem', null, '22rem', '26rem', '42rem'],
            maxHeight: '785px',
            mx: 'auto',
            '& > div': { pb: '100% !important' }
          }}
        />

        <Flex
          pt={['0.5rem', '0.5rem', '1.25rem']}
          sx={{
            justifyContent: 'center'
          }}
        >
          <ProductLink
            className="productName"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              textTransform: 'uppercase',
              fontWeight: 'medium',
              textAlign: 'center',
              letterSpacing: '2px'
              // marginBottom: ['4px', null, '6px']
            }}
            product={product}
          >
            <div sx={{ fontSize: ['1.7rem', null, '1.3rem', '2rem'] }}>
              {name}
            </div>
          </ProductLink>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Flex
          color="primary"
          sx={{
            justifyContent: 'center',
            paddingTop: ['12px', null, '5px'],
            paddingBottom: ['19px', null, '20px']
          }}
        >
          <ProductLink
            sx={{
              variant: 'text.small',
              letterSpacing: '3px'
            }}
            product={product}
          >
            <div sx={{ fontSize: ['1.2rem', '1.3rem', null, '2rem'] }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(price)}
            </div>
          </ProductLink>
        </Flex>
        {variants.length === 1 ? (
          <ProductAddToCartButton
            comingSoon={comingSoon}
            price={price}
            limitOnePerCustomer={limitOnePerCustomer}
            quantity={1}
            variantId={shopifyId}
            externalLink={product.externalProductPageUrl}
            regularPrice={regularPrice}
            sku={sku}
            sx={{
              width: ['95px', '145px', '171px', '236px'],
              height: ['28px', '33px', '32px', '44px'],
              margin: '0 auto',
              borderRadius: [0, '6px'],
              fontSize: ['1rem', '1.2rem', '1.3rem', '1.5rem'],
              fontWeight: ['body', 'bold']
            }}
          />
        ) : (
          <ProductChooseVariantButton
            product={product}
            sx={{
              width: ['135px', '145px', '171px', '236px'],
              height: ['28px', '33px', '32px', '44px'],
              margin: '0 auto',
              borderRadius: [0, '6px'],
              fontSize: ['1rem', '1.2rem', '1.3rem', '1.5rem'],
              fontWeight: ['body', 'bold'],
              color: 'white',
              borderColor: 'border'
            }}
          />
        )}
      </Flex>
    </Card>
  )
}

ProductSlide.propTypes = {
  product: PropTypes.shape({
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    sku: PropTypes.string,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

export default ProductSlide
