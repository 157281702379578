import PropTypes from 'prop-types'
import React from 'react'
import HeroSlider from '~/components/Generic/HeroSlider'
import ImageHero from '~/components/Generic/ImageHero'

const ContentHero = ({
  hero,
  gradient = false,
  fullScreen = true,
  ...props
}) => {
  return hero.slider ? (
    <HeroSlider slider={hero.slider} duration={hero.slideDuration} />
  ) : (
    <ImageHero
      {...props}
      heading={hero.heading}
      subheading={hero.subheading}
      imageHeading={hero.imageHeading}
      image={hero.image}
      gradient={gradient}
      fullScreen={fullScreen}
    />
  )
}

ContentHero.propTypes = {
  hero: PropTypes.shape({
    heading: PropTypes.shape({
      heading: PropTypes.string
    }),
    image: PropTypes.shape({
      fluid: PropTypes.shape()
    }).isRequired,
    subheading: PropTypes.shape({
      subheading: PropTypes.string
    })
  }),
  gradient: PropTypes.bool
}

export default ContentHero
