/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Container, Flex, Heading, jsx } from 'theme-ui'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { Fragment } from 'react'
import ImageHeading from './ImageHeading'
import { useLocation } from '@reach/router'

const ImageHeroWithGradient = ({ heading, image, subheading }) => {
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          position: 'relative'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image?.description ?? image?.title}
            sx={{
              height: '100%',
              py: ['2px'],
              minHeight: '108px',
              maxHeight: ['240px', '280px', '450px']
            }}
          />
        </Box>
        <Flex
          sx={{
            width: ['100%', '70%', '65%'],
            textAlign: ['center', 'left', 'left'],
            flexDirection: 'column',
            justifyContent: 'center',
            padding: ['1rem', '1rem', '4rem'],
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: [
              'rgba(0,0,0,.3)',
              'linear-gradient(to right,rgba(0,0,0,.2), rgba(0,0,0,.2) 60%, rgba(0,0,0,0))',
              null
            ]
          }}
        >
          <Heading as="h1" variant="h1" sx={{ color: 'background' }}>
            {heading.heading}
          </Heading>
          {subheading && (
            <Heading
              as="h3"
              variant="subhead"
              sx={{ marginTop: '1rem', color: 'background' }}
            >
              {subheading.subheading}
            </Heading>
          )}
        </Flex>
      </Flex>
    </Container>
  )
}

const ImageHeroWithoutGradient = ({
  heading,
  image,
  subheading,
  imageHeading,
  fullScreen,
  ...props
}) => {
  const location = useLocation()
  return (
    <Container
      as="section"
      {...props}
      variant={fullScreen ? 'fullWidth' : 'container'}
      sx={{
        marginTop: location.pathname === '/' ? 0 : 0,
        height: fullScreen ? '100vh' : 'auto',
        p: fullScreen ? null : ['0', '0 1rem', '0 4rem']
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          position: 'relative'
        }}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          {image?.file?.contentType?.split('/')[0] !== 'video' ? (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image?.description ?? image?.title}
              sx={{ height: '100%', minHeight: '300px' }}
            />
          ) : fullScreen ? (
            <video
              muted
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              loop
              playsInline
              autoPlay
            >
              <source src={image.file.url} type={image.file.contentType} />
            </video>
          ) : (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                pb: ['75.85%', null, '50%']
              }}
            >
              <video
                muted
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                loop
                playsInline
                autoPlay
              >
                <source src={image.file.url} type={image.file.contentType} />
              </video>
            </Box>
          )}
        </Box>
        <Flex
          className="text-container"
          sx={{
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: ['2rem 1rem', '5.5rem'],
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          {/* {imageHeading ? (
            <ImageHeading
              svg={imageHeading.svg}
              fluid={imageHeading.fluid}
              file={imageHeading.file}
              alt={imageHeading.file.filename}
            />
          ) : ( */}
          <Fragment>
            {heading && (
              <Heading as="h1" variant="h1" sx={{ color: 'background' }}>
                {heading.heading}
              </Heading>
            )}
            {subheading && (
              <Heading
                as="h3"
                variant="subhead"
                sx={{ marginTop: '1rem', color: 'background' }}
              >
                {subheading.subheading}
              </Heading>
            )}
          </Fragment>
          {/* )} */}
        </Flex>
      </Flex>
    </Container>
  )
}

export const ImageHero = ({ gradient = false, ...props }) => {
  return gradient ? (
    <ImageHeroWithGradient {...props} />
  ) : (
    <ImageHeroWithoutGradient {...props} />
  )
}

ImageHero.propTypes = {
  heading: PropTypes.shape({
    heading: PropTypes.string
  }),
  image: PropTypes.shape({
    fluid: PropTypes.shape()
  }).isRequired,
  subheading: PropTypes.shape({
    subheading: PropTypes.string
  }),
  gradient: PropTypes.bool
}

export default ImageHero
