/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import ProductLink from '~/components/Product/Link'

const ProductChooseVariantButton = ({ product, ctaText, ...props }) => {
  const translate = useTranslate()

  return (
    <Box
      {...props}
      p={0}
      sx={{
        variant: 'buttons.primary',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontFamily: 'primary',
        fontSize: [2, 2],
        letterSpacing: '1px',
        color: 'textMuted',
        borderColor: 'borderInactive'
      }}
    >
      <Flex sx={{ height: '100%' }}>
        <Flex
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center',
            padding: ['0 16px', null, '0 32px']
          }}
        >
          <ProductLink product={product}>
            {ctaText || translate('product.choose_option')}
          </ProductLink>
        </Flex>
      </Flex>
    </Box>
  )
}

ProductChooseVariantButton.propTypes = {
  disabled: PropTypes.bool,
  product: PropTypes.object.isRequired
}

export default ProductChooseVariantButton
