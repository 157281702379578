/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import ContentHero from '../Hero'
import ArticleSection from './Article'
import CollectionSection from './Collection'
import CollectionPreviewSection from './Collection/Preview'
import ContentCardSection from './ContentCard'
import FeaturedImagesSection from './FeaturedImages'
import ProductSection from './Product'
import QuoteSection from './Quote'
import SectionSlider from './Slider'
import TextSection from './Text'

const getMediaComponent = section => {
  if (!section.media || !section.media.length)
    return (
      <section sx={{ width: '100%' }}>
        <TextSection
          title={section.title}
          description={section.description}
          copy={section.copy}
          cta={section.cta}
          textAlign={section.textAlign}
        />
      </section>
    )

  // Multiple media *should* be of the same type
  const [{ __typename }] = section.media

  if (__typename === 'ContentfulProduct')
    return (
      <section sx={{ width: '100%' }}>
        <ProductSection
          products={section.media}
          title={section.title}
          description={section.description}
          cta={section.cta}
        />
      </section>
    )

  if (__typename === 'ContentfulCollection')
    return (
      <CollectionSection
        collections={section.media}
        title={section.title}
        description={section.description}
        sectionSlug={section.slug}
      />
    )

  if (__typename === 'ContentfulCollectionPreview')
    return (
      <section sx={{ width: '100%' }}>
        <CollectionPreviewSection sections={section.media} />
      </section>
    )

  if (__typename === 'ContentfulQuote')
    return (
      <section sx={{ width: '100%' }}>
        <QuoteSection quotes={section.media} />
      </section>
    )

  if (__typename === 'ContentfulContentCard')
    return (
      <section sx={{ width: '100%' }}>
        <TextSection
          description={section.description}
          cta={section.cta}
          sx={{ p: [0, '0 1rem', '0 4rem'] }}
        >
          <ContentCardSection contentCards={section.media} />
        </TextSection>
      </section>
    )

  if (__typename === 'ContentfulArticle')
    return (
      <section sx={{ width: '100%' }}>
        <ArticleSection
          title={section.title}
          heading={section.heading}
          description={section.description}
          articles={section.media}
          blog={section.blog}
        />
      </section>
    )

  if (__typename === 'ContentfulImageSlider')
    return (
      <SectionSlider slides={section.media ? section.media[0].slides : []} />
    )

  if (__typename === 'ContentfulFeaturedImages')
    return (
      <section sx={{ width: '100%' }}>
        <FeaturedImagesSection featuredImageSections={section.media} />
      </section>
    )

  if (__typename === 'ContentfulHero')
    return (
      <section sx={{ width: '100%' }}>
        <ContentHero hero={section.media[0]} fullScreen={false} />
      </section>
    )

  return null
}

const ContentSection = ({ section }) => getMediaComponent(section)

ContentSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string
    }),
    media: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

export default ContentSection
