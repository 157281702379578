import React from 'react'
import { Box } from '@theme-ui/components'
import CollectionPreview from '~/components/Collection/Preview'

const CollectionPreviewSection = ({ sections }) => {
  return (
    <Box>
      {sections.map(section => (
        <CollectionPreview section={section} />
      ))}
    </Box>
  )
}

export default CollectionPreviewSection
