import React from 'react'
import { Box, Button, Flex, Heading, Text } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
const DetailArticleCard = ({ article }) => {
  const { title, subheader, longDescription, slug, publishDate, image } =
    article
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const date = new Date(publishDate)
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: ['column', null, 'row'],
        alignItems: 'center',
        justifyContent: 'center',
        mb: '5.4rem'
      }}
    >
      <Box sx={{ width: ['100%', null, '40%'] }}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.description ?? image.title}
        />
      </Box>
      <Flex
        sx={{
          width: ['100%', null, '60%'],
          flexDirection: 'column',
          ml: [0, null, '7rem'],
          maxWidth: '670px',
          p: [4, null, 0]
        }}
      >
        <Heading sx={{ fontWeight: 'medium', fontSize: [3, 5], mb: '0.8rem' }}>
          {monthNames[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
        </Heading>
        <Heading as="h3" sx={{ fontWeight: 'medium', fontSize: [6, 8, 7, 8] }}>
          {title}
        </Heading>
        {subheader && (
          <Heading
            as="h4"
            sx={{ fontWeight: 'body', fontSize: [4, 4, 5, 6], mt: 3 }}
          >
            {subheader}
          </Heading>
        )}
        <Text>
          <div
            dangerouslySetInnerHTML={{
              __html: longDescription?.childMarkdownRemark.html
            }}
          />
        </Text>
        <Button
          as={Link}
          to={slug}
          sx={{
            height: '33px !important',
            lineHeight: '33px !important',
            fontWeight: 'medium',
            fontFamily: 'body',
            maxWidth: '180px',
            mt: ['4rem', null, '8rem']
          }}
        >
          READ MORE
        </Button>
      </Flex>
    </Flex>
  )
}

export default DetailArticleCard
