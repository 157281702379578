/**@jsx jsx */
import { jsx } from 'theme-ui'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HeroSlide from './Slide'

const HeroSlider = ({ slider, duration = 4000 }) => {
  return (
    <Slider
      autoplay
      autoplaySpeed={duration}
      pauseOnHover={false}
      lazyLoad={true}
      dots
      sx={{
        height: '100vh',
        '.slick-dots': {
          bottom: ['100px', '160px'],
          li: {
            margin: 0
          }
        },
        '.slick-prev, .slick-next': {
          transform: 'none',
          zIndex: 1
        },
        '.slick-prev': {
          left: ['10px', '60px']
        },
        '.slick-next': {
          right: ['10px', '60px']
        },
        '.slick-dots li button::before': {
          fontSize: '14px !important',
          color: 'white !important'
        }
      }}
    >
      {slider.map(slide => {
        return <HeroSlide key={slide.id} slide={slide} />
      })}
    </Slider>
  )
}

export default HeroSlider
