/** @jsx jsx */
import { Button, Heading, Container, jsx } from 'theme-ui'
import { useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useOnScreen } from '~/hooks/utils/use-on-screen'
import { Link } from 'gatsby'
import CardContent from './CardContent'
import SlideContent from './SlideContent'
import { GatsbyImage } from 'gatsby-plugin-image'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

const SectionSliderSlide = ({ slide }) => {
  const containerRef = useRef()
  const isMobileViewport = useMatchMedia('(max-width: 767px)')
  const isTabletViewport = useMatchMedia(
    '(min-width: 768px) and (max-width: 1023px)'
  )
  const onScreen = useOnScreen(containerRef, {
    threshold: isTabletViewport ? 0.1 : 0.6
  })
  return (
    <Fragment>
      {isMobileViewport && (
        <GatsbyImage
          image={slide.background?.gatsbyImageData}
          alt={slide.background?.description ?? slide.background?.title}
          sx={{
            width: '100vw',
            height: ['50vh', '100%']
          }}
          imgStyle={{
            objectPosition:
              slide.layoutAlignment === 'left' &&
              slide.layoutDirection === 'vertical' &&
              'left center'
          }}
        />
      )}

      <Container
        variant="fullWidth"
        as="section"
        sx={{
          height: ['auto', '100vh'],
          position: 'relative',
          flexFlow: 'column nowrap',
          justifyContent: ['flex-end', 'center'],
          alignItems: 'flex-end'
        }}
        ref={containerRef}
      >
        {!isMobileViewport && (
          <GatsbyImage
            image={slide.background?.gatsbyImageData}
            alt={slide.background?.description ?? slide.background?.title}
            sx={{
              position: 'absolute !important',
              top: 0,
              left: 0,
              width: '100%',
              height: ['50vh', '100%']
            }}
            imgStyle={{
              objectPosition:
                slide.layoutAlignment === 'left' &&
                slide.layoutDirection === 'vertical' &&
                '65% 50%'
            }}
          />
        )}

        {slide.media ? (
          <CardContent
            cards={slide.media}
            onScreen={onScreen}
            direction={slide.layoutDirection}
          />
        ) : (
          <SlideContent
            onScreen={onScreen}
            alignment={slide.layoutAlignment}
            direction={slide.layoutDirection}
          >
            <Heading
              as="h2"
              variant="h2"
              sx={{
                color: ['black', null, slide.textColor || 'white'],
                borderColor: ['black', null, slide.textColor || 'white'],
                fontWeight: 'medium',
                letterSpacing: '4px',
                fontSize: ['2.5rem', null, null, '2rem', '3.5rem'],
                mb: ['0.8rem', null, null, '1.2rem', '2.4rem']
              }}
            >
              {slide.title}
            </Heading>
            <div
              dangerouslySetInnerHTML={{
                __html: slide.description?.childMarkdownRemark.html
              }}
              sx={{
                fontSize: ['1.5rem', null, '1.3rem', null, '2rem'],
                marginBottom: ['2rem', null, null, '2.4rem', '3.2rem'],
                ul: { variant: 'list.bare' },
                li: { marginBottom: ['1.7rem', null, null, null, '3.7rem'] },
                p: {
                  margin: 0,
                  letterSpacing: '2px'
                },
                maxHeight: [null, null, !onScreen ? 0 : '50rem'],
                overflow: [null, null, 'hidden'],
                transition: 'max-height 0.3s ease-in-out 0.3s',
                color: ['black', null, slide.textColor || 'white']
              }}
            />
            {slide.cta ? (
              <Button
                variant="primary"
                as={Link}
                to={slide.slug}
                sx={{
                  '--color': ['white'],
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'max-content',
                  height: ['3.1rem', null, null, null, '4.4rem'],
                  margin: ['0 auto', null, 0]
                }}
              >
                {slide.cta}
              </Button>
            ) : null}
          </SlideContent>
        )}
      </Container>
    </Fragment>
  )
}

SectionSliderSlide.propTypes = {
  slide: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    background: PropTypes.shape({
      fluid: PropTypes.object.isRequired
    }),
    description: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    }),
    cta: PropTypes.string,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string,
        title: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            html: PropTypes.string.isRequired
          }).isRequired
        }),
        media: PropTypes.object
      }).isRequired
    ),
    layoutDirection: PropTypes.string,
    layoutAlignment: PropTypes.string
  }).isRequired
}

export default SectionSliderSlide
