/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import ContentCallToAction from '~/components/Content/Section/CallToAction'
import RichTextRenderer from '../../RichText'
import { useLocation } from '@reach/router'

const TextSection = ({
  title,
  copy,
  description,
  textAlign = 'center',
  children,
  cta,
  ...props
}) => {
  const { pathname } = useLocation()
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
        marginTop: pathname === 'dealer-locator' ? 0 : ['24px', '24px', '30px'],
        marginBottom: [0, 5],
        p: ['0 1rem', null, '0 4rem'],
        maxWidth: 'calc(1736px + 8rem)'
      }}
    >
      {title && (
        <Heading
          as="h1"
          sx={{
            color: 'primary',
            marginTop: ['16px', '16px', '36px'],
            marginBottom: '8px',
            fontWeight: 'bold',
            fontSize: ['2rem', '3rem', '4rem'],
            letterSpacing: ['0.2rem', '0.3rem', '0.4rem'],
            textTransform: 'none'
          }}
        >
          {title}
        </Heading>
      )}
      {description && (
        <Box
          sx={{
            textAlign: textAlign ? textAlign.toLowerCase() : 'center',
            '& p': {
              maxWidth: ['100%', null, '70%'],
              margin: 'auto',
              marginBottom: ['24px', null, '48px'],
              px: 1
            },
            '& > div': {
              mt: pathname === 'dealer-locator' ? 0 : null
            },
            a: {
              variant: 'text.link'
            }
          }}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html
          }}
        />
      )}
      {copy && (
        <Box
          sx={{
            'h2, h3, h4': {
              fontWeight: 'medium'
            },
            'ol ol': {
              listStyleType: 'lower-alpha !important'
            }
          }}
        >
          <RichTextRenderer richText={copy} />
        </Box>
      )}
      {children}
      {cta && (
        <Box sx={{ p: { mt: 0, mb: ['2.2rem', '3.2rem'] } }}>
          <ContentCallToAction
            sx={{
              textAlign: 'center',
              margin: 0
            }}
            content={cta}
          />
        </Box>
      )}
    </Flex>
  )
}

TextSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  collections: PropTypes.arrayOf(PropTypes.shape({})),
  cta: PropTypes.shape({}),
  children: PropTypes.shape({})
}

export default TextSection
