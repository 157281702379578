/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import CollectionCard from '~/components/Collection/Card'
import BikeCollectionSection from './Bike'
import { Fragment } from 'react'

const CollectionSection = ({ collections, sectionSlug, title }) => {
  const [
    {
      products: [sampleProduct]
    }
  ] = collections
  const collectionType = sampleProduct?.productType?.slug

  if (collectionType === 'electric-motorbike')
    return <BikeCollectionSection collections={collections} />

  return (
    <Fragment>
      {collections.map((collection, i) => (
        <CollectionCard
          key={collection.slug}
          index={i}
          collection={collection}
          sectionSlug={sectionSlug}
          sectionTitle={title}
          hideSecondBtn={true}
        />
      ))}
    </Fragment>
  )
}

CollectionSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  collections: PropTypes.arrayOf(PropTypes.shape({}))
}

export default CollectionSection
