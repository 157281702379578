/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import SectionSliderSlide from './Slide'
import { Fragment } from 'react'

const SectionSlider = ({ slides }) => {
  return (
    <Fragment>
      {slides.map(slide => {
        return (
          <SectionSliderSlide
            slide={slide}
            lockContentOnScroll={false}
            key={slide.id}
          />
        )
      })}
    </Fragment>
  )
}

SectionSlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      background: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }),
      description: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string.isRequired
        }).isRequired
      }),
      media: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string,
          title: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          description: PropTypes.shape({
            childMarkdownRemark: PropTypes.shape({
              html: PropTypes.string.isRequired
            }).isRequired
          }),
          media: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                gatsbyImageData: PropTypes.object.isRequired
              }).isRequired
            )
          ])
        })
      ),
      layoutDirection: PropTypes.string,
      layoutAlignment: PropTypes.string
    })
  ).isRequired
}

export default SectionSlider
