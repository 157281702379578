/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui'

const getFlexDirectionFromAlignment = alignment => {
  if (['left', 'top'].includes(alignment)) return 'flex-start'
  if (['right', 'bottom'].includes(alignment)) return 'flex-end'

  return 'center'
}

const SlideScrollingContent = ({ children, direction, alignment }) => {
  return (
    <Container
      sx={{
        position: ['relative', null, 'absolute'],
        top: [
          null,
          null,
          direction === 'vertical' ? 'var(--nav-bar-height)' : 'auto'
        ],
        bottom: [null, null, 0],
        left: [null, null, 0],
        right: [null, null, 0],
        display: [null, null, 'flex'],
        flexWrap: 'nowrap',
        flexDirection: [
          null,
          null,
          direction === 'vertical' ? 'column' : 'row'
        ],
        justifyContent: [null, null, getFlexDirectionFromAlignment(alignment)],
        alignItems: [null, null, direction === 'vertical' ? 'flex-end' : null],
        py: ['2.4rem', null, '4rem'],
        color: ['black', null, 'white'],
        backgroundColor: ['white', null, 'transparent'],
        textAlign: ['center', null, 'left']
      }}
    >
      <Box
        sx={{
          maxWidth: [null, null, direction === 'vertical' ? '42rem' : '65rem']
        }}
      >
        {children}
      </Box>
    </Container>
  )
}

const SlideContent = ({ children, ...props }) => {
  return <SlideScrollingContent {...props}>{children}</SlideScrollingContent>
}

export default SlideContent
