/** @jsx jsx */
import { Box, Button, jsx } from 'theme-ui'
import ProductSlide from '~/components/Product/Slide'
import ArrowBack from '~/assets/images/icons/s73-arrow-left.svg'
import ArrowNext from '~/assets/images/icons/s73-arrow-right.svg'
import { useWindowSize } from '~/hooks/utils/use-window-size'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SliderButton = ({ children, onClick, ariaLabel, forwardSx }) => (
  <Button
    variant="iconOnly"
    aria-label={ariaLabel}
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '35%',
      display: ['none', 'block'],
      zIndex: 1,
      ...forwardSx
    }}
  >
    {children}
  </Button>
)

const ProductCarousel = ({ products }) => {
  const { width } = useWindowSize()

  return (
    <Box>
      <Slider
        dots
        arrows
        infinite
        speed={500}
        slidesToShow={width < 768 ? 1 : 3}
        centerMode
        slidesToScroll={1}
        lazyLoad={true}
        prevArrow={
          <SliderButton ariaLabel="Previous" forwardSx={{ left: 0 }}>
            <ArrowBack sx={{ width: '20px', height: '20px' }} />
          </SliderButton>
        }
        nextArrow={
          <SliderButton ariaLabel="Next" forwardSx={{ right: 0 }}>
            <ArrowNext sx={{ width: '20px', height: '20px' }} />
          </SliderButton>
        }
        sx={{
          '.slick-slide': {
            px: ['10px', null, '20px', '50px', '70px']
          },
          '.slick-track': {
            display: 'flex',
            alignItems: 'stretch'
          },
          '.slick-slide': {
            height: 'auto',
            flex: 1,
            '& > div': {
              height: '100%'
            }
          },
          '.slick-dots': {
            bottom: ['-61px', '-44px', '-56px', '-60px'],
            left: '50%',
            display: 'flex !important',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            transform: 'translateX(-50%)',
            height: '15px',
            maxWidth: '600px',
            padding: '0 10px'
          },
          '.slick-dots li': {
            width: `calc(100% / ${products.length + 10})`,
            display: 'flex',
            alignItems: 'center'
          },
          '.slick-dots li button': {
            height: ['3px', '2.25px', '1.87', '3.5px'],
            width: '100%',
            backgroundColor: '#5D5D5D',
            borderRadius: '2px',
            boxShadow: 'none',
            margin: 0,
            padding: 0,
            opacity: 1,
            '::before': {
              content: '""'
            }
          },
          '.slick-dots li.slick-active button': {
            height: ['5.68px', '4.71px', '8.8px'],
            backgroundColor: 'rgba(0,0,0,0)',
            border: '1px solid black',
            transition: 'height 0.25s ease'
          }
        }}
      >
        {products.map(product => (
          <ProductSlide product={product} key={product.slug} />
        ))}
      </Slider>
    </Box>
  )
}

export default ProductCarousel
