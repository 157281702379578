/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui'
import { Fragment, useState } from 'react'
import BikeCollectionCard from '~/components/Collection/Card/Bike'
import { useBannerContext } from '~/components/Layout'

const BikeCollectionSection = ({ collections }) => {
  const [activeCollection, setActiveCollection] = useState()
  const BannerContext = useBannerContext()

  const handleVisibilityChange = (collectionId, isVisible) => {
    if (activeCollection === collectionId && !isVisible)
      setActiveCollection(null)

    if (isVisible) setActiveCollection(collectionId)
  }

  return (
    <Fragment>
      <Container
        as="nav"
        variant="fullWidth"
        sx={{
          position: 'absolute',
          top: 0,
          left: [
            'var(--horizontal-inset)',
            null,
            null,
            null,
            'calc((100vw - var(--width-constraint)) / 2 + var(--horizontal-inset))'
          ],
          right: ['var(--horizontal-inset)', null, 'auto'],
          width: 'auto',
          height: [null, null, '100vh'],
          opacity: activeCollection ? 1 : 0,
          zIndex: 2,
          overflow: 'hidden',
          transition: 'opacity 0.3s ease-in-out',
          scrollSnapAlign: 'none'
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            px: [
              'var(--horizontal-inset)',
              null,
              null,
              null,
              'calc((100vw - var(--width-constraint)) / 2 + var(--horizontal-inset))'
            ],
            width: ['100%', null, 'auto'],
            display: 'flex',
            flexFlow: ['row', null, 'row-reverse'],
            justifyContent: ['space-between', null, 'center'],
            height: [null, null, '100vh'],
            textAlign: 'center',
            color: 'white',
            writingMode: [null, null, 'vertical-lr'],
            pt: [
              BannerContext.showBanner
                ? `calc(var(--nav-bar-height) + 2.4rem + ${BannerContext.bannerHeight}px)`
                : 'calc(var(--nav-bar-height) + 2.4rem)',
              null,
              null,
              'calc(var(--nav-bar-height) / 2)'
            ],
            pb: [0, null, 'calc(var(--nav-bar-height) + 2.4rem)'],
            transform: [null, null, 'rotate(180deg)'],
            opacity: activeCollection ? 1 : 0,
            visibility: activeCollection ? 'visible' : 'hidden',
            transition: 'opacity 2s, visibility 2s, padding-top 0.25s linear'
          }}
        >
          {collections.map((collection, index) => (
            <a
              variant="clear"
              href={`#${collection.title}`}
              key={collection.id}
              sx={{
                fontSize: 3,
                fontWeight: 'medium',
                textTransform: 'uppercase',
                display: 'block',
                opacity: activeCollection === collection.id ? 1 : 0.6,
                transition: 'all 0.3s',
                ':hover': {
                  opacity: 1
                },
                ':not(:last-of-type)': {
                  marginTop: [null, null, '16rem']
                }
              }}
            >
              {collection.title}
            </a>
          ))}
        </Box>
      </Container>

      {collections.map(c => (
        <BikeCollectionCard
          collection={c}
          onVisibilityChange={isVisible =>
            handleVisibilityChange(c.id, isVisible)
          }
          key={c.id}
        />
      ))}
    </Fragment>
  )
}

export default BikeCollectionSection
