/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, Heading, jsx, Card } from 'theme-ui'
import BikeCarousel from '~/components/Product/Carousel/Bike'
import ProductCarousel from '~/components/Product/Carousel'

const ProductSection = ({ title, description, products, cta }) => {
  const [sampleProduct] = products
  const productType = sampleProduct?.productType?.slug
  const isBike = productType === 'electric-motorbike'

  return (
    <Card
      sx={{
        padding: ['0', '0', '0'],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: !isBike ? 'center' : 'null',
        height: !isBike ? [null, null, null, 'auto'] : null,
        borderRadius: 0,
        margin: '0 auto',
        pt: isBike ? 0 : ['8rem', '8rem', '8rem', '12rem'],
        mb: isBike ? 0 : ['16rem', null, null, '17rem'],
        width: '100%'
      }}
    >
      {!isBike && (title || description) ? (
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Heading
            sx={{
              textTransform: 'uppercase',
              letterSpacing: '3.5px',
              mb: ['12px', '5px'],
              fontWeight: 'medium',
              fontSize: ['2.5rem', null, null, '4rem']
            }}
          >
            {title}
          </Heading>

          {description?.childMarkdownRemark && (
            <div
              sx={{
                mb: '10px',
                a: {
                  textDecoration: 'underline',
                  fontSize: '13px',
                  fontWeight: 'medium'
                }
              }}
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          )}
        </Flex>
      ) : null}

      {isBike ? (
        <BikeCarousel bikes={products} />
      ) : (
        <ProductCarousel products={products} />
      )}
    </Card>
  )
}

ProductSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  cta: PropTypes.shape({})
}

export default ProductSection
