/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Flex, jsx } from 'theme-ui'
import ImageMarquee from '../Marquee/ImageMarquee'

const FeaturedImages = ({ heading, images, carousel, wrapImages }) => {
  return carousel ? (
    <ImageMarquee heading={heading} images={images} />
  ) : (
    <Container>
      <Flex
        sx={{
          position: 'relative',
          flexWrap: wrapImages ? 'wrap' : 'nowrap',
          width: '100%'
        }}
      >
        {images.map((image, i) => {
          return (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image?.description ?? image?.title}
              key={image.id}
              sx={{
                width: ['100%', images.length === 1 ? '100%' : '49%'],
                maxHeight: '869px',
                mb: ['1.5rem', null, '3.5rem'],
                mr: wrapImages
                  ? [0, i === 0 && images.length !== 1 ? '2%' : 0]
                  : [
                      i === 0 && images.length !== 1 ? '7px' : 0,
                      i === 0 && images.length !== 1 ? '2%' : 0
                    ]
              }}
            />
          )
        })}
      </Flex>
    </Container>
  )
}

export default FeaturedImages
