import React from 'react'
import { Box } from '@theme-ui/components'
import FeaturedImages from '~/components/Generic/FeaturedImages'

const FeaturedImagesSection = ({ featuredImageSections }) => {
  return (
    <Box>
      {featuredImageSections.map(section => {
        return (
          <FeaturedImages
            key={section.id}
            heading={section.heading}
            title={section.title}
            images={section.images}
            carousel={section.carousel}
            wrapImages={section.wrapImages}
          />
        )
      })}
    </Box>
  )
}

export default FeaturedImagesSection
