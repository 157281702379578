/**@jsx jsx */
import { Box, Flex, Heading, Text } from '@theme-ui/components'
import { jsx } from 'theme-ui'
import { useEffect, useState } from 'react'

const HeroSlide = ({ slide }) => {
  const {
    media,
    poster,
    title,
    description,
    link,
    linkText,
    textColor,
    textDropshadow
  } = slide

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 5000)
  }, [])

  return (
    <Flex
      sx={{
        height: '100vh',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        {media.file.contentType.includes('video') ? (
          <video
            muted
            poster={poster?.gatsbyImageData?.images?.fallback?.src}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            loop
            playsInline
            autoPlay
          >
            <source src={media.file.url} type={media.file.contentType} />
          </video>
        ) : (
          <img
            loading="eager"
            onLoad={() => setLoaded(true)}
            alt={media.description ?? media.title}
            src={media.gatsbyImageData?.images?.fallback?.src}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: loaded ? 1 : 0,
              transition: 'opacity 0.25s ease'
            }}
          />
        )}
      </Box>
      {(title || description || link) && (
        <Flex
          sx={{
            position: 'relative',
            zIndex: 1,
            flexDirection: 'column',
            color: textColor || '#000000',
            alignItems: 'center',
            textAlign: 'center',
            padding: '0 45px'
          }}
        >
          {title && (
            <Heading
              sx={{
                fontWeight: 'heading',
                fontSize: [7, '4.8rem'],
                textShadow: textDropshadow && '0 0 15px black'
              }}
            >
              {title}
            </Heading>
          )}
          {description?.childMarkdownRemark && (
            <Text
              sx={{
                fontSize: [5, 7],
                textShadow: textDropshadow && '0 0 5px black',
                maxWidth: '620px',
                lineHeight: '120%',
                fontWeight: 'medium',
                p: {
                  mb: [4, 5],
                  mt: [3, 4]
                }
              }}
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          )}
          {link && linkText && (
            <a
              sx={{
                fontSize: [3, '1.7rem'],
                variant: 'buttons.primary',
                color: 'white !important',
                backgroundColor: 'black !important',
                mt: !description && 3,
                height: 'auto !important',
                padding: '15px 22px',
                lineHeight: '100% !important'
              }}
              href={link}
            >
              {linkText}
            </a>
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default HeroSlide
