/** @jsx jsx */
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Flex, Heading, jsx, Text } from 'theme-ui'
import ProductLink from '~/components/Product/Link'

const CollectionPreview = ({ section }) => {
  const { title, cta, image, collection } = section
  const parentPath = collection.lineup[0].lineup
    ? collection.lineup[0].lineup[0].slug
    : collection.lineup[0].slug
  const productsToShow = collection.products.slice(0, 3)

  return (
    <Container variant="container">
      <Flex
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          mb: ['1.5rem', null, '3.5rem']
        }}
      >
        <Box
          sx={{
            maxWidth: ['100%', null, '45%'],
            width: '100%',
            position: 'relative',
            mr: [0, null, '6%'],
            mb: ['3rem', null, 0]
          }}
        >
          {image && (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image?.description ?? image?.title}
              sx={{ maxHeight: '869px' }}
            />
          )}
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', null, image ? '49%' : '100%'],
            px: ['1rem', null, 0],
            justifyContent: 'center'
          }}
        >
          {title && (
            <Heading
              as="h3"
              sx={{
                fontSize: ['2rem', '3rem'],
                fontWeight: 'body',
                mb: '0.8rem'
              }}
            >
              {title}
            </Heading>
          )}
          <Flex sx={{ width: '100%' }}>
            {productsToShow.map((product, i) => {
              return (
                <Box
                  sx={{
                    width: '100%',
                    position: 'relative',
                    ml: i !== 0 ? '1.3rem' : 0,
                    ':last-of-type': { display: ['none', 'block'] },
                    '&:hover': {
                      '& > a > div:last-of-type, & > form': {
                        opacity: 1,
                        visibility: 'visible'
                      }
                    }
                  }}
                >
                  <ProductLink product={product}>
                    <GatsbyImage
                      alt={
                        product.mainImage.description ?? product.mainImage.title
                      }
                      image={product.mainImage?.gatsbyImageData}
                    />

                    {product.images && product.images[0] && (
                      <GatsbyImage
                        image={product.images[0].gatsbyImageData}
                        sx={{
                          opacity: 0,
                          visibility: 'hidden',
                          position: 'absolute !important',
                          left: 0,
                          top: 0,
                          width: '100%',
                          height: '100%',
                          transition:
                            'opacity 0.25s ease, visibility 0.25s ease'
                        }}
                      />
                    )}
                  </ProductLink>
                </Box>
              )
            })}
          </Flex>
          {cta && (
            <Link to={`/${parentPath}/${collection.slug}`}>
              <Text
                variant="link"
                sx={{
                  textAlign: 'right',
                  fontSize: ['1.2rem', null, '2rem'],
                  mt: ['0.6rem', null, '1.6rem']
                }}
              >
                {cta}
              </Text>
            </Link>
          )}
        </Flex>
      </Flex>
    </Container>
  )
}

export default CollectionPreview
